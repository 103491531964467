// en	zh-CN	zh-TW	de	es-MX	es-ES	th	vi	fr	id	it	ja	ko	pt-BR

export const LANGs = [
  { value: 'en', label: 'English' },
  { value: 'en-SG', label: 'English - SEA' },
  { value: 'zh-CN', label: '简体中文' },
  { value: 'zh-TW', label: '繁體中文' },
  { value: 'de', label: 'Deutsch' },
  { value: 'es-MX', label: 'Español - LA' },
  { value: 'es-ES', label: 'Español - ES' },
  { value: 'th', label: 'ภาษาไทย' },
  { value: 'vi', label: 'Tiếng Việt' },
  { value: 'fr', label: 'Français' },
  { value: 'id', label: 'Indonesia' },
  { value: 'it', label: 'Italiano' },
  { value: 'ja', label: '日本語' },
  { value: 'ko', label: '한국어' },
  { value: 'pt-BR', label: 'Português - BR' },
  { value: 'ru', label: 'Pусский язык' }
];

export const lang2RedeemMap = {
  en: 'us-en',
  'en-SG': 'us-en',
  'zh-CN': 'us-zh-Hant',
  'zh-TW': 'us-zh-Hant',
  // "de": "us-en",
  'es-MX': 'us-es',
  'es-ES': 'us-es',
  th: 'us-th',
  // "fr": "",
  id: 'us-id',
  // "it": "",
  ja: 'us-ja',
  ko: 'us-ko',
  'pt-BR': 'us-pt'
  // "ru": ""
};

export const DEFAULT_LANG = 'en';
