import React from 'react';
import {CSSTransition} from 'react-transition-group';
import './style.less';

export default function Modal({show, onClose, showClose = true, children}) {
  return (
    <CSSTransition in={show} timeout={300} classNames="modal" unmountOnExit={true}>
      <div className="modal-wrap">
        <div className="modal-content">
          <div styleName="inner-content">
            {children}
            {showClose && <div styleName="close-btn" onClick={onClose}></div>}
          </div>
        </div>
      </div>
    </CSSTransition>
  );
}
