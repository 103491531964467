export const isNode = typeof window === 'undefined';
export const isBrowser = !isNode;

const getPSM = () => {
  if (isBrowser || typeof process === 'undefined') {
    return '-';
  }
  const psm = process.env.LOAD_SERVICE_PSM || process.env.TCE_PSM || '-';
  return psm;
};

export const isInternal = getPSM().endsWith('_internal');

export const emptyFn = () => {
  //
};
export const isDev = process.env.NODE_ENV === 'development';
export const isBoe = process.env.TCE_HOST_ENV === 'boe';
export const isTestEnv =
  (isBrowser && document.cookie.includes('env=test')) || (isNode && isBoe);

const publicPath = isTestEnv
  ? '//p1.dailygn.com/obj/'
  : '//s16b.tiktokcdn.com/toutiao/fe/long_cache/';
export const baseUrl = isDev ? '/' : publicPath;
export const isIOS = isBrowser && /iPhone/i.test(window.navigator.userAgent);
export const isAndroid =
  isBrowser && /Android/i.test(window.navigator.userAgent);

export const isByte =
  isBrowser &&
  (/(NewsArticle)[\s/_](\d.\d.\d)/i.test(window.navigator.userAgent) ||
    /aweme_\d+/i.test(window.navigator.userAgent) ||
    /live_stream_\d+/i.test(window.navigator.userAgent) ||
    /VideoArticle/i.test(window.navigator.userAgent) ||
    /Super \d.\d.\d/i.test(window.navigator.userAgent));
export const isGSDK =
  isBrowser && /BDGameSDK/i.test(window.navigator.userAgent);
export const isWeixin =
  isBrowser && /micromessenger/i.test(window.navigator.userAgent);
export const isQQ =
  isBrowser && /QQ\/([\d.]+)/.test(window.navigator.userAgent) && !isWeixin;
export const isQQBrowser =
  isBrowser &&
  /MQQBrowser/i.test(window.navigator.userAgent) &&
  !isWeixin &&
  !isQQ;
export const isQzone = isBrowser && /Qzone/i.test(window.navigator.userAgent);
export const isUcAndroidBrowser =
  isBrowser && /UCBrowser/i.test(window.navigator.userAgent) && isAndroid;
export const isUcIosBrowser =
  isBrowser && /UCBrowser/i.test(window.navigator.userAgent) && isIOS;

export function getServicePSM() {
  return `ttgame.platform.gms_act_gateway${isInternal ? '_internal' : ''}`;
}

export function getCgameShiftServicePSM() {
  return isInternal
    ? 'ttgame.platform.gactivity_sandbox'
    : 'ttgame.platform.gactivity';
}
