import React from 'react';

export const getSpecFont = (lang, hasDefault = true) => {
  // 德法西葡意
  if (
    lang === 'de' ||
    lang === 'fr' ||
    lang === 'es-MX' ||
    lang === 'es-ES' ||
    lang === 'pt-BR' ||
    lang === 'it' ||
    lang === 'vi'
  ) {
    return 'ft_ptes';
  } else if (lang === 'ja') {
    return 'ft_ja';
  } else if (lang === 'th') {
    return 'ft_tl';
  }
  if (lang === 'ko') {
    return 'ft_kr_zw';
  }
  if (hasDefault) {
    return 'ft_italic';
  }
  return null;
};

// 新闻详情专用
export const getSpecFontForNews = (lang, hasDefault = true) => {
  // 德法西葡意
  if (
    lang === 'de' ||
    lang === 'fr' ||
    lang === 'es-MX' ||
    lang === 'es-ES' ||
    lang === 'pt-BR' ||
    lang === 'it'
  ) {
    return 'ft_ptes_news';
  } else if (lang === 'ja') {
    return 'ft_ja';
  } else if (lang === 'th') {
    return 'ft_tl';
  }
  if (lang === 'ko') {
    return 'ft_kr_zw';
  }
  if (hasDefault) {
    return 'ft_italic';
  }
  return null;
};

// 韩语将<br>转成换行
export const getSpecLine = (text, lang) => {
  if (lang !== 'ko') {
    return text;
  }
  // 韩语处理
  let words = text.split('<br>');
  return (
    <>
      {words.map((word, index) => (
        <>
          {word}
          {words.length - 1 !== index && <br />}
        </>
      ))}
    </>
  );
};
