import { useTranslation } from 'react-i18next';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import '../../../utils/hoverCss/index.less';
import './index.less';
import { customRedirect } from '~/utils/link/goPage';
import { getTime } from '~/utils/getTime';
import { getSpecFont, getSpecLine } from '~/utils/fontClass/index';
import { getNews } from '~/common/services/siteData';

export default function Section02() {
  const { t, i18n } = useTranslation();
  const lock = useRef(false);
  const cache = useRef({});
  const [newsLength, setNewsLength] = useState(0);
  const [news, setNews] = useState([]);
  const heroDom = useRef(null);
  const onReadMore = () => {
    customRedirect('./newslist');
    try {
      (window as any)._cookie_fb_switch_ && (window as any).fbq('track', 'Search');
      (window as any)._cookie_sp_switch_ && (window as any).snaptr('track', 'SEARCH');
      (window as any)._cookie_tt_switch_ && (window as any).ttq.track('Search');
      (window as any)._cookie_gg_switch_ && (window as any).gtag_report_conversion3();
      (window as any)._cookie_tw_switch_ &&
        (window as any).twttr.conversion.trackPid('o8quo', {
          tw_sale_amount: 0,
          tw_order_quantity: 0
        });
      (window as any)._cookie_rd_switch_ && (window as any).rdt('track', 'Search');
    } catch (_) {}
  };

  const goDetail = useCallback((curTab) => {
    // logDetail(`${curTab.description}-${curTab.id}`);
    if (curTab.url) {
      customRedirect(curTab.url, true);
      return;
    }
    customRedirect(`./newsdetail?id=${curTab.id}`, false);
  }, []);
  const styleHeight = useCallback(() => {
    if (newsLength === 1) {
      return '12.23rem';
    } else if (newsLength === 2) {
      return '10.53rem';
    } else {
      return '12.88rem';
    }
  }, [newsLength]);

  const specScale = useCallback((lang) => {
    if (lang === 'fr') {
      return { transform: 'scale(0.8)' };
    }
    return {};
  }, []);

  useEffect(() => {
    // 获取置顶的3条新闻
    const init = async () => {
      let lang = i18n.language;
      if (cache.current[lang]) {
        let data = cache.current[lang];
        setNews(data);
        setNewsLength(data.length);
        return;
      }
      if (lock.current) {
        return;
      }
      lock.current = true;
      const res = await getNews({
        type: 'ALL',
        page: 1,
        size: 10,
        language: lang
      });
      let data = res.data.slice(0, 3);
      setNews(data);
      setNewsLength(data.length);
      lock.current = false;
      cache.current[lang] = data;
    };
    init();
  }, [t]);

  return (
    <div styleName="section02_wrap">
      <div styleName="news-wrap">
        <div styleName="empty"></div>
        <div styleName="section02_title_wrap" style={{ width: styleHeight() }}>
          <div
            styleName="section02_title"
            id="sec2"
            style={specScale(i18n.language)}
            className={getSpecFont(i18n.language)}
          >
            {t('latest_news')}
          </div>
          <div styleName="arrows" onClick={() => onReadMore()}>
            <span className={getSpecFont(i18n.language)}>{t('read_more')}</span>
            <span></span>
          </div>
        </div>
        {/* 只有一篇新闻 */}
        {newsLength <= 1 &&
          news.map((item, index) => {
            return (
              <div key={index} styleName="home_news_01_wrap" onClick={() => goDetail(item)}>
                <div styleName="home_01_bg">
                  <div styleName="incline_block_01"></div>
                  <img src={item?.image} alt="" />
                </div>
                <div styleName="home_01_right">
                  <div styleName="home_01_title" className={getSpecFont(i18n.language, false)}>
                    {item?.description}
                  </div>
                  <div styleName="home_01_desc" className={getSpecFont(i18n.language, false)}>
                    {item?.title}
                  </div>
                  <div styleName="home_01_date" className={getSpecFont(i18n.language, false)}>
                    {getTime(item?.update_at * 1000, 'MM/dd/yyyy')}
                  </div>
                  <div styleName="home_01_arrow"></div>
                </div>
              </div>
            );
          })}

        {/* 只有两篇新闻兼容 */}
        {newsLength === 2 && (
          <div styleName="home_news_wrap_2">
            {news.map((item, index) => {
              return (
                <div key={index} styleName="home_newss_2" onClick={() => goDetail(item)}>
                  <div styleName="home_news_bg_2">
                    <div styleName="incline_block_02"></div>
                    <img src={item?.image} alt="" />
                  </div>

                  <div styleName="home_news_title_2" className={getSpecFont(i18n.language, false)}>
                    {item?.description}
                  </div>
                  <div styleName="home_news_desc_2" className={getSpecFont(i18n.language, false)}>
                    {item?.title}
                  </div>

                  <div styleName="home_news_date_2" className={getSpecFont(i18n.language, false)}>
                    {getTime(item?.update_at * 1000, 'MM/dd/yyyy')}
                  </div>
                </div>
              );
            })}
          </div>
        )}

        {/* 3偏以上 */}
        {newsLength > 2 && (
          <div styleName="home_news_wrap">
            {news.map((item, index) => {
              return (
                <div key={index} styleName="home_newss" onClick={() => goDetail(item)}>
                  <div styleName="home_news_bg">
                    <div styleName="incline_block"></div>
                    <img src={item?.image} alt="" />
                  </div>

                  <div styleName="home_news_title" className={getSpecFont(i18n.language, false)}>
                    {item?.description}
                  </div>
                  <div styleName="home_news_desc" className={getSpecFont(i18n.language, false)}>
                    {item?.title}
                  </div>

                  <div styleName="home_news_date" className={getSpecFont(i18n.language, false)}>
                    {getTime(item?.update_at * 1000, 'MM/dd/yyyy')}
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>

      {/* superHero */}
      <div styleName="hero_wrap">
        <div styleName="hero_left">
          <div styleName="hero_title" id="sec3" className={getSpecFont(i18n.language)}>
            {t('overview')}
          </div>
          <div styleName="hero_desc" className={getSpecFont(i18n.language, false)}>
            {getSpecLine(t('collect_deploy_destroy'), i18n.language)}
          </div>
          <div styleName="hero_contain" className={getSpecFont(i18n.language, false)}>
            {getSpecLine(t('marvel_snap_unleashes_the_complete'), i18n.language)}
          </div>
        </div>
        <div styleName="hero_right">
          <div styleName="inner-hero" ref={heroDom}></div>
        </div>
      </div>
    </div>
  );
}
