/* eslint-disable import/no-anonymous-default-export */
export default {
  appId: 262304,
  actId: 136,
  appName: 'appName',
  region: 'sg',
  enableShark: false,
  title: 'snap官网',
  description: '描述',
  image: ''
};
