// @ts-nocheck
import { useTranslation } from 'react-i18next';
import React, { useCallback, useEffect, useState } from 'react';
import { Tea } from '@fe/game-sdk';
import googlePlay from '../../../assets/googleplay_btn.png';
import appStore from '../../../assets/appstore_btn.png';
import windows from '../../../assets/window_btn.png';
import { logWatchYouTube } from '~/utils/log';
import { getSpecFont } from '~/utils/fontClass/index';
import { getYoutubeVideo } from '../../../common/services/siteData';
import '../../../utils/hoverCss/index.less';
import './index.less';

// const getStyle = (lang) => {
//   let scale = 1;
//   if (
//     lang === 'de' ||
//     lang === 'fr' ||
//     lang === 'th' ||
//     lang === 'it' ||
//     lang === 'id' ||
//     lang === 'ru' ||
//     lang === 'ja' ||
//     lang === 'ko'
//   ) {
//     scale = 0.8;
//   } else if (lang === 'es-MX' || lang === 'es-ES' || lang === 'pt-BR') {
//     scale = 0.6;
//   }
//   return { transform: `translateX(-50%) scale(${scale})` };
// };

export default function Section01() {
  const { t, i18n } = useTranslation();
  const [videoPop, setVideoPop] = useState(false);
  const [youTubeLink, setYouTubeLink] = useState('');

  // const [hideSlide, setHideSlide] = useState(false);
  // const [hideClose, setHideClose] = useState(!hideSlide);

  const handleChangeVideo = async () => {
    logWatchYouTube();
    setVideoPop(true);
    try {
      (window as any)._cookie_fb_switch_ && (window as any).fbq('track', 'ViewContent');
      (window as any)._cookie_sp_switch_ && (window as any).snaptr('track', 'VIEW_CONTENT');
      (window as any)._cookie_tt_switch_ && (window as any).ttq.track('Subscribe');
      (window as any)._cookie_gg_switch_ && (window as any).gtag_report_conversion2();
      (window as any)._cookie_tw_switch_ &&
        (window as any).twttr.conversion.trackPid('o8qv1', {
          tw_sale_amount: 0,
          tw_order_quantity: 0
        });
      (window as any)._cookie_rd_switch_ && (window as any).rdt('track', 'View Content');
    } catch (_) {}
  };

  const closeVideo = () => {
    setVideoPop(false);
  };

  const specFontWeight = useCallback((lang) => {
    let bold = ['zh-CN', 'zh-TW', 'ko', 'th'];
    if (bold.some((item) => item === lang)) {
      return { fontWeight: 'bold' };
    }
    return {};
  }, []);

  const reportTea = (system) => {
    Tea.sendLogV3('acv_button_touch', {
      component_text: `${system}_bottom_${i18n.language}`,
      is_score: 1,
      process_type: 999,
      page_text: 'download',
      component_name: 'be_null',
      component_id: 'be_null'
    });
  };
  // const jumpTo = () => {
  //   try {
  //     let dom = document.getElementById('sendemail');
  //     window.scrollTo({
  //       top: dom.offsetTop - 80,
  //       behavior: 'smooth'
  //     });
  //     closeSlide();
  //   } catch (_) {}
  // };

  // const closeSlide = () => {
  //   setHideSlide(true);
  //   setTimeout(() => {
  //     setHideClose(false);
  //   }, 300);
  // };

  // const openSlide = () => {
  //   setHideClose(true);
  //   setTimeout(() => {
  //     setHideSlide(false);
  //   }, 300);
  // };

  useEffect(() => {
    getYoutubeVideo(true, i18n.language).then((videoData) => {
      if (videoData) {
        setYouTubeLink(videoData.url);
      }
    });
  }, [t]);

  return (
    <div styleName="section01_wrap">
      <div styleName="video_pop_wrap" style={{ display: videoPop ? 'block' : 'none' }}>
        <div styleName="video_inner">
          {videoPop && (
            <>
              <iframe
                width="100%"
                height="100%"
                src={youTubeLink}
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen={true}
              ></iframe>
              <div styleName="close_pop" onClick={() => closeVideo()}></div>
            </>
          )}
        </div>
      </div>
      {!!youTubeLink && (
        <div styleName="play_video_icon" onClick={handleChangeVideo}>
          <span styleName="circle circle-1"></span>
          <span styleName="circle circle-2"></span>
          <span styleName="circle circle-3"></span>
        </div>
      )}

      <div styleName="day-countdown-wrap" className={getSpecFont(i18n.language)}>
        <span style={specFontWeight(i18n.language)}>{t('download_now!')}</span>
      </div>
      {/* <a
        href="https://play.google.com/store/apps/details?id=com.nvsgames.snap"
        styleName="glow-on-hover downlink android"
        target="_blank"
        onClick={() => reportTea('android')}
      >
        <img src={googlePlay} />
      </a>
      <a
        href="https://apps.apple.com/app/id1592081003"
        styleName="glow-on-hover downlink ios"
        target="_blank"
        onClick={() => reportTea('ios')}
      >
        <img src={appStore} />
      </a> */}
      <div styleName="download-btns">
        <a
          href="https://play.google.com/store/apps/details?id=com.nvsgames.snap"
          styleName="glow-on-hover downlink"
          target="_blank"
          onClick={() => reportTea('android')}
        >
          <img src={googlePlay} />
        </a>
        <a
          href="https://apps.apple.com/app/id1592081003"
          styleName="glow-on-hover downlink"
          target="_blank"
          onClick={() => reportTea('ios')}
        >
          <img src={appStore} />
        </a>
        <a
          href="https://store.steampowered.com/app/1997040/"
          styleName="glow-on-hover downlink"
          target="_blank"
          onClick={() => reportTea('pc')}
        >
          <img src={windows} />
        </a>
      </div>
      {/* <div styleName={hideSlide ? 'open-slide hide-slide' : 'open-slide'}>
        <span
          styleName="words"
          style={getStyle(i18n.language)}
          className={getSpecFont(i18n.language)}
        >
          {t('send_email_sidebutton')}
        </span>
        <span styleName="jump" onClick={jumpTo}></span>
        <span styleName="clo" onClick={closeSlide}></span>
      </div>
      <div
        onClick={openSlide}
        styleName={hideClose ? 'close-slide hide-close-slide' : 'close-slide'}
      ></div> */}
    </div>
  );
}
