import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import Starling from '@ies/starling_client';
import Cookies from 'js-cookie';
import { localBrowserLanguageDetecter } from '../../utils/credits';

const starlingOptions: any = {
  api_key: '4ab7d06029a011edb233c11c2b8a3138',
  namespace: ['snap_website'],
  zoneHost: 'https://starling-oversea.byteoversea.com',
  // locale: 'en',
  // test: location.host === 'act-va.bytedance.net', // TODO 线上环境注释
  fallbackLang: ['en']
};

const getCurrentLanguage = () => {
  const lang = Cookies.get('i18next');
  return lang;
};

const getLocalStarlingText = (lang: string) => {
  const key = `text.${starlingOptions.api_key}.${starlingOptions.namespace[0]}.${lang}`;
  const text = localStorage.getItem(key);
  if (text) {
    try {
      return JSON.parse(text);
    } catch {
      //
    }
  }
  return null;
};

class Backend {
  static type: string = 'backend';
  services: any;
  options: any;
  constructor(services, options = {}) {
    this.init(services, options);
  }
  init(services, options = {}) {
    this.services = services;
    this.options = options;
  }
  read(locale, namespace, callback) {
    const starling = new Starling({ ...this.options, namespace, locale });
    starling.load((result) => {
      callback(null, result);
    });
  }
}

const initI18n = () => {
  i18n
    .use(Backend as any)
    .use(initReactI18next)
    .use(LanguageDetector)
    .init({
      // 优先通过语言检测，如果未检测出，会用该降级语言
      fallbackLng: 'en',
      // load: 'languageOnly', // we only provide en, de -> no region specific locals like en-US, de-DE
      // have a common namespace used around the full app
      ns: ['snap_website'],
      defaultNS: 'snap_website',
      saveMissing: true,
      debug: false,
      react: {
        useSuspense: false
      },
      interpolation: {
        escapeValue: false,
        prefix: '{',
        suffix: '}'
      },
      // wait: process && !process.release,
      detection: {
        caches: ['cookie', 'localStorage'],
        order: [
          // 'querystring',
          'cookie',
          'localStorage',
          'navigator',
          // 'htmlTag',
          // 'path',
          // 'subdomain'
        ],
        cookieMinutes: 60 * 24 * 365,
        // lookupQuerystring: 'lng',
        lookupCookie: 'i18next',
        lookupLocalStorage: 'i18nextLng'
      },
      backend: starlingOptions
    });

  const localLng = getCurrentLanguage();
  // console.log('localLng', localLng)
  const localTexts = getLocalStarlingText(localLng);
  if (localTexts) {
    // console.log('cache txt', localTexts)
    i18n.addResources(localLng, 'snap_website', localTexts);
    try {
      const starling = new Starling({ ...starlingOptions, locale: localLng });
      starling.load(result => {
        // console.log('reload txt', result);
        i18n.addResources(localLng, 'snap_website', result);
      });
    } catch (_) { }
  } else {
    let dfl = localBrowserLanguageDetecter();
    console.log('dfl', dfl)
    i18n.changeLanguage(dfl);
  }
  // 
  // 监听语言变化，自动设置对应的预约title
  i18n.on('languageChanged', function (lng) {
    try {
      console.log('lng', lng)
      // console.log('now title:', document.title, '\nnow lang:', localLng);
      // console.log(i18n.t('begin'))
      let title = i18n.t('page_title');
      document.title = title !== 'page_title' ? title : 'MARVEL SNAP - Dominate the Marvel Multiverse in High-Speed Card Battling Action';
    } catch (_) { }
  })
};

if (typeof window !== 'undefined') {
  initI18n();
}
