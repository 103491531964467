import React, { useEffect } from 'react';
import { Tea, gameUtils } from '@fe/game-sdk';
import { isBrowser } from '../common/utils/env';
import { useTranslation } from 'react-i18next';
import config from '../config';
// import usePageBrowseEvent from '../common/hooks/usePageBrowseEvent';

let hasInit = false;
export default function BaseContainer({ children }) {
  const start = Date.now();
  const { i18n } = useTranslation();
  const lang = i18n.language;
  if (isBrowser && !hasInit) {
    Tea.initSendLogV3({
      app_id: config.appId,
      activity_id: config.actId,
      zone: config.region,
      // 非必传参数，这个字段可以自定义通用参数，并且会在所有埋点事件里统一传参
      default_data: {
        page_text: config.title,
        activity_form: '4'
      }
    });
    const query = gameUtils.parseQuery();
    Tea.sendLogV3('acv_pageview', {
      index: 1,
      entrance_source: query.entrance_source ? query.entrance_source : 'unknown',
      entrance_detail: query.entrance_detail ? query.entrance_detail : 'be_null'
    });
    hasInit = true;
  }

  // usePageBrowseEvent();

  useEffect(() => {
    const query = gameUtils.parseQuery();
    Tea.sendLogV3('acv_pageload', {
      index: 1,
      entrance_source: query.entrance_source ? query.entrance_source : 'unknown',
      entrance_detail: query.entrance_detail ? query.entrance_detail : 'be_null',
      duration: Date.now() - start
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (lang) {
      Tea.setLogParam({
        language: lang
      });
    }
  }, [lang]);

  return children;
}
