import { CookieBanner } from '@fe/game-sdk';

// 默认拒绝收集用户数据 tie1
export const countryTier1 = [
  'AT',
  'BE',
  'BG',
  'HR',
  'CY',
  'CZ',
  'DK',
  'EE',
  'FI',
  'FR',
  'DE',
  'GR',
  'HU',
  'IE',
  'IT',
  'LV',
  'LT',
  'LU',
  'MT',
  'NL',
  'PL',
  'PT',
  'RO',
  'SK',
  'SI',
  'ES',
  'SE',
  'IS',
  'LI',
  'NO',
  'GB',
  'CH',
  'TR'
];
// 默认同意收集 tie2
export const countryTier2 = ['BR', 'MX', 'UY', 'AR', 'CO'];

let initFacebook = false;
let initSnapchat = false;
let initGoogle = false;
let initTwitter = false;
let initTiktok = false;
let initReddit = false;

const initFacebookPixel = (enable) => {
  // head内
  try {
    window._cookie_fb_switch_ = enable;
    if (!enable) {
      return;
    }
    if (initFacebook) {
      return;
    }
    let head = document.getElementsByTagName('head')[0];
    let script = document.createElement('script');
    script.innerHTML = `
    !function(f,b,e,v,n,t,s)
    {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
    n.callMethod.apply(n,arguments):n.queue.push(arguments)};
    if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
    n.queue=[];t=b.createElement(e);t.async=!0;
    t.src=v;s=b.getElementsByTagName(e)[0];
    s.parentNode.insertBefore(t,s)}(window, document,'script',
    'https://connect.facebook.net/en_US/fbevents.js');
    fbq('init', '1393750854471514');
    fbq('track', 'PageView');
    `;
    head.appendChild(script);
    initFacebook = true;
  } catch (e) {}
};
const initSnapchatPixel = (enable) => {
  // head内
  try {
    window._cookie_sp_switch_ = enable;
    if (!enable) {
      return;
    }
    if (initSnapchat) {
      return;
    }
    let head = document.getElementsByTagName('head')[0];
    let script = document.createElement('script');
    script.innerHTML = `
    (function(e,t,n){if(e.snaptr)return;var a=e.snaptr=function()
      {a.handleRequest?a.handleRequest.apply(a,arguments):a.queue.push(arguments)};
      a.queue=[];var s='script';r=t.createElement(s);r.async=!0;
      r.src=n;var u=t.getElementsByTagName(s)[0];
      u.parentNode.insertBefore(r,u);})(window,document,
      'https://sc-static.net/scevent.min.js');
      
      snaptr('init', '41a681f3-2d36-49cd-8389-fdc0fe1adb51', {
      'user_email': '__INSERT_USER_EMAIL__'
      });
      
      snaptr('track', 'PAGE_VIEW');
    `;
    head.appendChild(script);
    initSnapchat = true;
  } catch (e) {}
};
const initGooglePixel = (enable) => {
  // head内
  try {
    window._cookie_gg_switch_ = enable;
    if (!enable) {
      return;
    }
    if (initGoogle) {
      return;
    }
    let head = document.getElementsByTagName('head')[0];
    let scriptLink = document.createElement('script');
    let scriptCode = document.createElement('script');
    scriptLink.src = 'https://www.googletagmanager.com/gtag/js?id=AW-10892319757';
    scriptLink.async = true;
    scriptCode.innerHTML = `
    window.dataLayer = window.dataLayer || [];
    function gtag() {
      dataLayer.push(arguments);
    }
    gtag('js', new Date());

    gtag('config', 'AW-10892319757');
    `;
    head.appendChild(scriptLink);
    head.appendChild(scriptCode);
    initGoogle = true;
  } catch (e) {}
};
const initTwitterPixel = (enable) => {
  // head内
  try {
    window._cookie_tw_switch_ = enable;
    if (!enable) {
      return;
    }
    if (initTwitter) {
      return;
    }
    let head = document.getElementsByTagName('head')[0];
    let script = document.createElement('script');
    script.src = '//static.ads-twitter.com/oct.js';
    head.appendChild(script);
    initTwitter = true;
  } catch (e) {}
};
const initTikTokPixel = (enable) => {
  // head内
  try {
    window._cookie_tt_switch_ = enable;
    if (!enable) {
      return;
    }
    if (initTiktok) {
      return;
    }
    let head = document.getElementsByTagName('head')[0];
    let script = document.createElement('script');
    script.innerHTML = `
    !(function (w, d, t) {
      w.TiktokAnalyticsObject = t;
      var ttq = (w[t] = w[t] || []);
      (ttq.methods = [
        'page',
        'track',
        'identify',
        'instances',
        'debug',
        'on',
        'off',
        'once',
        'ready',
        'alias',
        'group',
        'enableCookie',
        'disableCookie'
      ]),
        (ttq.setAndDefer = function (t, e) {
          t[e] = function () {
            t.push([e].concat(Array.prototype.slice.call(arguments, 0)));
          };
        });
      for (var i = 0; i < ttq.methods.length; i++) ttq.setAndDefer(ttq, ttq.methods[i]);
      (ttq.instance = function (t) {
        for (var e = ttq._i[t] || [], n = 0; n < ttq.methods.length; n++)
          ttq.setAndDefer(e, ttq.methods[n]);
        return e;
      }),
        (ttq.load = function (e, n) {
          var i = 'https://analytics.tiktok.com/i18n/pixel/events.js';
          (ttq._i = ttq._i || {}),
            (ttq._i[e] = []),
            (ttq._i[e]._u = i),
            (ttq._t = ttq._t || {}),
            (ttq._t[e] = +new Date()),
            (ttq._o = ttq._o || {}),
            (ttq._o[e] = n || {});
          var o = document.createElement('script');
          (o.type = 'text/javascript'), (o.async = !0), (o.src = i + '?sdkid=' + e + '&lib=' + t);
          var a = document.getElementsByTagName('script')[0];
          a.parentNode.insertBefore(o, a);
        });

      ttq.load('C9UGU4JC77UCFR8RBVPG');
      ttq.page();
    })(window, document, 'ttq');
    `;
    head.appendChild(script);
    initTiktok = true;
  } catch (e) {}
};
const initRedditPixel = (enable) => {
  // head内
  try {
    window._cookie_rd_switch_ = enable;
    if (!enable) {
      return;
    }
    if (initReddit) {
      return;
    }
    let head = document.getElementsByTagName('head')[0];
    let script = document.createElement('script');
    script.innerHTML = `
    !function(w,d){if(!w.rdt){var p=w.rdt=function(){p.sendEvent?p.sendEvent.apply(p,arguments):p.callQueue.push(arguments)};p.callQueue=[];var t=d.createElement("script");t.src="https://www.redditstatic.com/ads/pixel.js",t.async=!0;var s=d.getElementsByTagName("script")[0];s.parentNode.insertBefore(t,s)}}(window,document);rdt('init','t2_micbqy3r');rdt('track', 'PageVisit');
    `;
    head.appendChild(script);
    initReddit = true;
  } catch (e) {}
};

export const controlCookie = (data) => {
  let keys = Object.keys(data);
  keys.forEach((key) => {
    let item = data[key];
    let name = item.name.toLowerCase();
    if (name.indexOf('meta') > -1) {
      initFacebookPixel(item.enable);
    } else if (name.indexOf('snapchat') > -1) {
      initSnapchatPixel(item.enable);
    } else if (name.indexOf('google') > -1) {
      initGooglePixel(item.enable);
    } else if (name.indexOf('twitter') > -1) {
      initTwitterPixel(item.enable);
    } else if (name.indexOf('tiktok') > -1) {
      initTikTokPixel(item.enable);
    } else if (name.indexOf('reddit') > -1) {
      initRedditPixel(item.enable);
    }
  });
};

export const acceptAllCookie = () => {
  initFacebookPixel(true);
  initSnapchatPixel(true);
  initGooglePixel(true);
  initTwitterPixel(true);
  initTikTokPixel(true);
  initRedditPixel(true);
};

export const getCountrySiteConfig = (countryCode) => {
  if (!countryCode) {
    return;
  }
  if (countryTier1.some((country) => country === countryCode)) {
    return {
      appId: 262304,
      website: 93,
      page: 6,
      block: 31,
      channel: 37,
      language: 'en-US',
      region: 'va'
    };
  } else if (countryTier2.some((country) => country === countryCode)) {
    return {
      appId: 262304,
      website: 93,
      page: 6,
      block: 31,
      channel: 38,
      language: 'en-US',
      region: 'va'
    };
  } else {
    return null;
  }
};

export const initCookieControl = ({ currentCity, ref, done }) => {
  const siteConfig = getCountrySiteConfig(currentCity);
  if (!siteConfig && currentCity !== 'US') {
    // 不需要cookie弹窗，并初始化所有pixel
    acceptAllCookie();
    return;
  }
  // 获取配置数据
  ref.current = new CookieBanner({
    siteParam: siteConfig,
    needOptOut: currentCity === 'US',
    optOutOptions: {
      el: '#opt-out-button',
      appId: '262304',
      onAcceptAll: () => {
        acceptAllCookie();
      },
      onRejectAll: () => console.info('[reject-all]')
    }
  });
  if (currentCity === 'US') {
    // 美国 不需要执行下面的cookie-setting
    return;
  }
  ref.current.listen('ready', () => {
    let status = ref.current.getStatus();
    if (status.code == 0) {
      // 第一次需要监听接受or拒绝数据
      ref.current.listen('acceptAll', (data) => {
        controlCookie(data);
      });
      ref.current.listen('rejectAll', (data) => {
        controlCookie(data);
      });
    } else {
      // 接受或者拒绝过，需要初始化对应的sdk & 管控参数
      let data = ref.current.getSetting();
      controlCookie(data);
    }
    ref.current.listen('save', (data) => {
      controlCookie(data);
    });
  });

  done();
};
