export const termOfService = {
  en:
    'https://sf16-draftcdn-sg.ibytedtos.com/obj/ies-hotsoon-draft-sg/Snap/snap_terms_of_service_en.html',
  'zh-CN':
    'https://sf16-draftcdn-sg.ibytedtos.com/obj/ies-hotsoon-draft-sg/Snap/snap_terms_of_service_en.html',
  'zh-TW':
    'https://sf16-draftcdn-sg.ibytedtos.com/obj/ies-hotsoon-draft-sg/Snap/snap_terms_of_service_zh-hant.html',
  de:
    'https://sf16-draftcdn-sg.ibytedtos.com/obj/ies-hotsoon-draft-sg/Snap/snap_terms_of_service_de.html',
  'es-MX':
    'https://sf16-draftcdn-sg.ibytedtos.com/obj/ies-hotsoon-draft-sg/Snap/snap_terms_of_service_es.html',
  'es-ES':
    'https://sf16-draftcdn-sg.ibytedtos.com/obj/ies-hotsoon-draft-sg/Snap/snap_terms_of_service_es.html',
  th:
    'https://sf16-draftcdn-sg.ibytedtos.com/obj/ies-hotsoon-draft-sg/Snap/snap_terms_of_service_en.html',
  vi: 'https://funtapcorp.vn/dieu-khoan',
  fr:
    'https://sf16-draftcdn-sg.ibytedtos.com/obj/ies-hotsoon-draft-sg/Snap/snap_terms_of_service_fr.html',
  id:
    'https://sf16-draftcdn-sg.ibytedtos.com/obj/ies-hotsoon-draft-sg/Snap/snap_terms_of_service_id.html',
  it:
    'https://sf16-draftcdn-sg.ibytedtos.com/obj/ies-hotsoon-draft-sg/Snap/snap_terms_of_service_it.html',
  ja:
    'https://sf16-draftcdn-sg.ibytedtos.com/obj/ies-hotsoon-draft-sg/Snap/snap_terms_of_service_ja.html',
  ko:
    'https://sf16-draftcdn-sg.ibytedtos.com/obj/ies-hotsoon-draft-sg/Snap/snap_terms_of_service_ko.html',
  'pt-BR':
    'https://sf16-draftcdn-sg.ibytedtos.com/obj/ies-hotsoon-draft-sg/Snap/snap_terms_of_service_pt.html',
  ru:
    'https://sf16-draftcdn-sg.ibytedtos.com/obj/ies-hotsoon-draft-sg/Snap/snap_terms_of_service_ru.html'
};
export const nuversePrivacyNotice = {
  en:
    'https://sf16-draftcdn-sg.ibytedtos.com/obj/ies-hotsoon-draft-sg/Snap/c0a3e1e6-a53d-4a0d-82b4-2e8097015584.html',
  'zh-CN':
    'https://sf16-draftcdn-sg.ibytedtos.com/obj/ies-hotsoon-draft-sg/Snap/c0a3e1e6-a53d-4a0d-82b4-2e8097015584.html',
  'zh-TW':
    'https://sf19-draftcdn-sg.ibytedtos.com/obj/ies-hotsoon-draft-sg/Snap/snap_privacy_policy_zh-hant.html',
  de:
    'https://sf16-draftcdn-sg.ibytedtos.com/obj/ies-hotsoon-draft-sg/Snap/c0a3e1e6-a53d-4a0d-82b4-2e8097015584_de.html',
  'es-MX':
    'https://sf16-draftcdn-sg.ibytedtos.com/obj/ies-hotsoon-draft-sg/Snap/c0a3e1e6-a53d-4a0d-82b4-2e8097015584.html',
  'es-ES':
    'https://sf16-draftcdn-sg.ibytedtos.com/obj/ies-hotsoon-draft-sg/Snap/c0a3e1e6-a53d-4a0d-82b4-2e8097015584.html',
  th:
    'https://sf16-draftcdn-sg.ibytedtos.com/obj/ies-hotsoon-draft-sg/Snap/c0a3e1e6-a53d-4a0d-82b4-2e8097015584.html',
  vi: 'https://funtapcorp.vn/bao-mat',
  fr:
    'https://sf16-draftcdn-sg.ibytedtos.com/obj/ies-hotsoon-draft-sg/Snap/c0a3e1e6-a53d-4a0d-82b4-2e8097015584_fr.html',
  id:
    'https://sf16-draftcdn-sg.ibytedtos.com/obj/ies-hotsoon-draft-sg/Snap/c0a3e1e6-a53d-4a0d-82b4-2e8097015584_id.html',
  it:
    'https://sf16-draftcdn-sg.ibytedtos.com/obj/ies-hotsoon-draft-sg/Snap/c0a3e1e6-a53d-4a0d-82b4-2e8097015584_it.html',
  ja:
    'https://sf16-draftcdn-sg.ibytedtos.com/obj/ies-hotsoon-draft-sg/Snap/c0a3e1e6-a53d-4a0d-82b4-2e8097015584_ja.html',
  ko:
    'https://sf16-draftcdn-sg.ibytedtos.com/obj/ies-hotsoon-draft-sg/Snap/c0a3e1e6-a53d-4a0d-82b4-2e8097015584_ko.html',
  'pt-BR':
    'https://sf16-draftcdn-sg.ibytedtos.com/obj/ies-hotsoon-draft-sg/Snap/c0a3e1e6-a53d-4a0d-82b4-2e8097015584_pt.html',
  ru:
    'https://sf16-draftcdn-sg.ibytedtos.com/obj/ies-hotsoon-draft-sg/Snap/c0a3e1e6-a53d-4a0d-82b4-2e8097015584_ru.html'
};
export const secondDinnerPrivacyNotice = {
  en:
    'https://sf19-draftcdn-sg.ibytedtos.com/obj/ies-hotsoon-draft-sg/Snap/seconddinner_privacy_notice_en.html',
  'zh-CN':
    'https://sf19-draftcdn-sg.ibytedtos.com/obj/ies-hotsoon-draft-sg/Snap/seconddinner_privacy_notice_en.html',
  'zh-TW':
    'https://sf19-draftcdn-sg.ibytedtos.com/obj/ies-hotsoon-draft-sg/Snap/seconddinner_privacy_notice_zh-hant.html',
  de:
    'https://sf19-draftcdn-sg.ibytedtos.com/obj/ies-hotsoon-draft-sg/Snap/seconddinner_privacy_notice_de.html',
  'es-MX':
    'https://sf19-draftcdn-sg.ibytedtos.com/obj/ies-hotsoon-draft-sg/Snap/seconddinner_privacy_notice_es-LA.html',
  'es-ES':
    'https://sf19-draftcdn-sg.ibytedtos.com/obj/ies-hotsoon-draft-sg/Snap/seconddinner_privacy_notice_es-ES.html',
  th:
    'https://sf19-draftcdn-sg.ibytedtos.com/obj/ies-hotsoon-draft-sg/Snap/seconddinner_privacy_notice_en.html',
  vi:
    'https://sf19-draftcdn-sg.ibytedtos.com/obj/ies-hotsoon-draft-sg/Snap/seconddinner_privacy_notice_en.html',
  fr:
    'https://sf19-draftcdn-sg.ibytedtos.com/obj/ies-hotsoon-draft-sg/Snap/seconddinner_privacy_notice_fr.html',
  id:
    'https://sf19-draftcdn-sg.ibytedtos.com/obj/ies-hotsoon-draft-sg/Snap/seconddinner_privacy_notice_id.html',
  it:
    'https://sf19-draftcdn-sg.ibytedtos.com/obj/ies-hotsoon-draft-sg/Snap/seconddinner_privacy_notice_it.html',
  ja:
    'https://sf19-draftcdn-sg.ibytedtos.com/obj/ies-hotsoon-draft-sg/Snap/seconddinner_privacy_notice_ja.html',
  ko:
    'https://sf19-draftcdn-sg.ibytedtos.com/obj/ies-hotsoon-draft-sg/Snap/seconddinner_privacy_notice_ko.html',
  'pt-BR':
    'https://sf19-draftcdn-sg.ibytedtos.com/obj/ies-hotsoon-draft-sg/Snap/seconddinner_privacy_notice_pt.html',
  ru:
    'https://sf16-draftcdn-sg.ibytedtos.com/obj/ies-hotsoon-draft-sg/Snap/9b030535-5608-419d-8102-e1da598bae7d_ru.html'
};
