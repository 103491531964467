import { Tea } from '@fe/game-sdk';

const btnClick = (
  component_text: string,
  page_text = 'snap官网',
  process_type = '999',
  is_core = '1'
) => {
  Tea.sendLogV3('acv_button_touch', {
    component_text,
    page_text,

    process_type,
    is_core,
    component_name: 'BE_NULL',
    process_id: 'BE_NULL',
    component_id: 'BE_NULL'
  });
};
const btnSend = (
  component_text: string,
  is_success = '',
  page_text = 'snap官网',
  process_type = '999',
  is_core = '1'
) => {
  Tea.sendLogV3('acv_button_touch', {
    component_text,
    is_success,
    page_text,

    process_type,
    is_core,
    component_name: 'BE_NULL',
    process_id: 'BE_NULL',
    component_id: 'BE_NULL'
  });
};
const pageView = (page_text, news_id = '') => {
  Tea.sendLogV3('acv_pageview', {
    page_text,
    news_id
  });
};
export const logSendEmail = (isSend) => {
  btnSend('email_register', isSend);
};

export const logTopScrollEamil = () => {
  btnClick('top_register');
};

export const logWatchYouTube = () => {
  btnClick('watch_trailer');
};

export const logAssociationLink = (channel) => {
  btnClick('social_media', channel);
};

export const logAgreeCookie = () => {
  btnClick('cookie');
};

export const logNextNews = () => {
  btnClick('next_news');
};

export const logPrevNews = () => {
  btnClick('previous_news');
};
export const logMoreNews = () => {
  btnClick('load_more_news');
};

export const logBigPic = (url) => {
  btnClick('press_content', url);
};

export const logDownPic = (picSource) => {
  btnClick('press_download', picSource);
};
// pageView
export const logViewHome = (homeView) => {
  pageView(homeView);
};

export const logDetail = (dtlId) => {
  pageView('news_detail', dtlId);
};
export const logMedia = () => {
  pageView('press_kit');
};
