export const LANGs = [
  { value: 'en', label: 'English' },
  { value: 'en-SG', label: 'English - SEA' },
  { value: 'zh-CN', label: '简体中文' },
  { value: 'zh-TW', label: '繁體中文' },
  { value: 'de', label: 'Deutsch' },
  { value: 'es-MX', label: 'Español - LA' },
  { value: 'es-ES', label: 'Español - ES' },
  { value: 'th', label: 'ภาษาไทย' },
  // { value: 'vi', label: 'Tiếng Việt' },
  { value: 'fr', label: 'Français' },
  { value: 'id', label: 'Indonesia' },
  { value: 'it', label: 'Italiano' },
  { value: 'ja', label: '日本語' },
  { value: 'ko', label: '한국어' },
  { value: 'pt-BR', label: 'Português - BR' },
  { value: 'ru', label: 'Pусский язык' }
];

export const LOADINGMap = {
  en: 'Loading...',
  'en-SG': 'Loading...',
  'zh-CN': '加载中...',
  'zh-TW': '正在下載……',
  de: 'Lädt...',
  'es-MX': 'Cargando...',
  'es-ES': 'Cargando...',
  th: 'กำลังโหลด...',
  vi: 'Đang tải...',
  fr: 'Chargement...',
  id: 'Memuat...',
  it: 'Caricamento...',
  ja: 'ロード中…',
  ko: '로딩 중...',
  'pt-BR': 'Carregando...',
  ru: 'Загрузка...'
};

export const siteMap = {
  en: 'en-US',
  'en-SG': 'en-sea',
  'zh-CN': 'zh-CN',
  'zh-TW': 'zh-TW',
  de: 'de_DE',
  'es-MX': 'es-LA',
  'es-ES': 'es_ES',
  th: 'th-TH',
  vi: 'vi-VN',
  fr: 'fr_FR',
  id: 'id-ID',
  it: 'it-IT',
  ja: 'ja-JP',
  ko: 'ko_KR',
  'pt-BR': 'pt_BR',
  ru: 'ru_RU'
};

export const localBrowserLanguageDetecter = () => {
  try {
    const language = (navigator.language || '').toLowerCase();
    if (language.indexOf('en') > -1) {
      return 'en';
    } else if (language === 'zh-cn') {
      return 'zh-CN';
    } else if (language.indexOf('zh') > -1) {
      return 'zh-TW';
    } else if (language.indexOf('de') > -1) {
      return 'de';
    } else if (language === 'es-mx' || language === 'es-419') {
      return 'es-MX';
    } else if (language.indexOf('es') > -1) {
      return 'es-ES';
    } else if (language.indexOf('th') > -1) {
      return 'th';
    } /* else if (language.indexOf('vi') > -1) {
      return 'vi';
    } */ else if (
      language.indexOf('fr') > -1
    ) {
      return 'fr';
    } else if (language.indexOf('id') > -1) {
      return 'id';
    } else if (language.indexOf('it') > -1) {
      return 'it';
    } else if (language.indexOf('ja') > -1) {
      return 'ja';
    } else if (language.indexOf('ko') > -1) {
      return 'ko';
    } else if (language.indexOf('pt') > -1) {
      return 'pt-BR';
    } else if (language.indexOf('ru') > -1 || language.indexOf('be') > -1) {
      return 'ru';
    } else {
      return 'en';
    }
  } catch (_) {
    return 'en';
  }
};
