import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { LANGs } from './config';

const isValidLang = (v: string) => {
  return Boolean(LANGs.find((item) => item.value === v));
};

export const useLang = () => {
  const { i18n } = useTranslation();
  const lang = i18n.language;

  const languageLabel = useMemo(() => {
    return LANGs.find((item) => item.value === lang)?.label || '-';
  }, [lang]);

  const changeLanguage = useCallback(
    (v: string) => {
      if (isValidLang(v)) {
        i18n.changeLanguage(v);
      } else {
        console.error('set lang error:', v);
      }
    },
    [i18n]
  );

  return {
    language: lang,
    languageLabel,
    changeLanguage
  };
};
