// eslint-disable-next-line filenames/match-exported
import { useCallback, useEffect, useState } from 'react';
import { logViewHome } from '~/utils/log';

export default function useFloatUp({ list, offsetTop = 70, delay = 100, LogList = [] }) {
  const [showedId, setShowId] = useState<any>({});

  // 判断是否在视口内
  const isInViewport = useCallback(
    (el) => {
      const { top } = el.getBoundingClientRect();
      const clientHeight =
        window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
      return top + offsetTop <= clientHeight;
    },
    [offsetTop]
  );

  // 滚动事件
  const handleScroll = useCallback(
    (domList) => {
      const translateList = [];

      domList.forEach((domItem) => {
        if (showedId[domItem.id]) {
          return;
        }

        if (isInViewport(domItem.dom)) {
          translateList.push(domItem);
        }
      });
      translateList.forEach((translateItem, index) => {
        // translateItem.dom.style.transitionDelay = `${index * delay}ms`;
        // translateItem.dom.style.transform = 'translateY(0)';
        // translateItem.dom.style.opacity = '1';

        LogList.map((logItem, idx) => {
          if (logItem.id === translateItem.id) {
            logViewHome(logItem.name);
          }
        });

        setShowId((ids) => {
          ids[translateItem.id] = true;

          return ids;
        });
      });
    },
    [showedId, isInViewport, LogList]
  );

  // 添加类
  const init = useCallback(() => {
    const curDomList = [];
    list.forEach((id) => {
      const curDom: HTMLElement = document.querySelector(`#${id}`);
      if (!curDom) {
        return;
      }

      // curDom.style.transform = 'translateY(2rem)';
      // curDom.style.transitionProperty = 'opacity, transform';
      // curDom.style.transitionDuration = '0.8s';
      // curDom.style.opacity = '0';
      curDomList.push({
        id,
        dom: curDom
      });
    });
    document.onscroll = () => handleScroll(curDomList);

    setTimeout(() => {
      handleScroll(curDomList);
    }, 0);
  }, [list, handleScroll]);

  useEffect(() => {
    setShowId({});
  }, [list]);

  useEffect(() => {
    init();
    init();
  }, [init]);
}
