import { useCallback, useEffect, useState } from 'react';
import { getLimitArea } from '../services/siteData';

export default function useAreaLimit() {
  //  'TW'
  const [areaList] = useState(['KP', 'IR', 'CU', 'SY', 'CRIMEA', 'DT', 'LH', 'CN']);
  const [currentCity, setCurrentCity] = useState('');
  const [isLimit, setIsLimit] = useState(false);

  const handelGetCity = useCallback(async () => {
    const data = await getLimitArea();
    try {
      // 存全局
      window['_em_cd_'] = data.country_code;
    } catch (_) { }
    setCurrentCity(data?.country_code);
    areaList.forEach((item) => {
      if (data?.region_code === item || data?.country_code === item) {
        setIsLimit(true);
      }
    });
  }, [areaList]);
  useEffect(() => {
    handelGetCity();
  }, [handelGetCity]);
  return { currentCity, isLimit };
}
