const routers = [
  'index',
  'newslist',
  'medialist',
  'newsdetail',
  'textdetail',
  'privacy',
  'm_privacy',
  'm_index',
  'm_newslist',
  'm_medialist',
  'm_newsdetail',
  'm_textdetail',
  'credits',
  'm_credits',
  'howtoplay',
  'm_howtoplay',
  'm_creatorprogram',
  'creatorprogram'
];
const resolveUrl = (url) => {
  if (url.indexOf('//') > -1) {
    return url;
  }

  let baseUrl = `${location.protocol}//${location.host}${location.pathname}`;
  // baseUrl = baseUrl.replace()
  if (/\/gms\/(\w|\d)*/.test(baseUrl)) {
    const replaceArr = baseUrl.split('/gms/')[1];
    if (replaceArr.length > 1) {
      baseUrl = `${baseUrl.replace(replaceArr, replaceArr.split('/')[0])}/`;
    }
  }
  routers.forEach((route) => {
    baseUrl = baseUrl.replace(`/${route}`, '');
  });

  if (!baseUrl.endsWith('/')) {
    baseUrl += '/';
  }
  // baseUrl = baseUrl.replace(/[^\/]+\.html/i, '');
  const base = document.createElement('base');
  base.href = baseUrl;

  const head = document.getElementsByTagName('head')[0];
  head.insertBefore(base, head.firstChild);

  const a = document.createElement('a');

  a.href = url;
  const resolved = a.href;

  head.removeChild(base);

  return resolved;
};
function isMobile() {
  // 如果手动指定了设备类型 则直接使用
  if (location.search.includes('__gms__platform=mobile')) {
    return true;
  } else if (location.search.includes('__gms__platform=pc')) {
    return false;
  }
  const ua = navigator.userAgent;
  const tablet = /tablet/i.test(ua) && !/tablet pc/i.test(ua);
  const whiteList = ['android', 'iphone', 'symbianos', 'windows phone', 'ipod', 'ipad'];
  return whiteList.includes(ua.toLowerCase()) || (!tablet && /[^-]mobile/i.test(ua));
}

export const customRedirect = (href, new_open = false, opt?: any) => {
  opt = opt || {};
  href = resolveUrl(href);
  if (new_open && !isMobile() && !opt.self) {
    const win = window.open('_blank');
    win.location = href;
  } else if (opt.replace) {
    location.replace(href);
  } else {
    location.href = href;
  }
};
