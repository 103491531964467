import { request as httpRequest } from '@fe/game-sdk';
import i18n from 'i18next';
import config from '../config';

export interface Resp {
  code: number;
  message: string;
  logId: string;
  data: any;
  [key: string]: any;
}

export interface ProcessResp {
  process_exec_code: number;
  copywriting: string;
  [key: string]: any;
}

const executingMap = new Map<string, boolean>();
export async function execProcess(processId, options = {}): Promise<ProcessResp> {
  if (!processId) {
    throw new Error('no process id');
  }
  if (executingMap.get(processId)) {
    return new Promise(() => {
      return undefined;
    });
  }
  executingMap.set(processId, true);
  try {
    const data = await bricksPost(`/act/${config.appId}/process/exec/v2`, {
      process_id: processId,
      ...options
    });
    executingMap.delete(processId);
    return data;
  } catch (error) {
    executingMap.delete(processId);
    throw error;
  }
}

const batchExecutingMap = new Map<string | number, boolean>();
export async function batchExecProcess(
  process_args?: { process_id: string; [key: string]: any }[]
) {
  const key = JSON.stringify(process_args);
  if (batchExecutingMap.get(key)) {
    return new Promise(() => {
      return undefined;
    });
  }

  batchExecutingMap.set(key, true);
  try {
    const data = await bricksPost(`/act/${config.appId}/process/batch/exec/v2`, { process_args });
    batchExecutingMap.delete(key);
    return data;
  } catch (e) {
    batchExecutingMap.delete(key);
    throw e;
  }
}

const commonData = {
  activity_id: String(config.actId),
  login_type: 'passport',
  channel_app_id: String(config.appId)
};

export function bricksPost(url, options: any = {}) {
  return request(url, { method: 'POST', body: { ...commonData, ...options } });
}

export function bricksGet(url, options: any = {}) {
  return request(url, { method: 'GET', params: { ...commonData, ...options } });
}

export async function request(url: string, options: any = {}) {
  const headers = options.headers
    ? {
        ...options.headers,
        'X-REQUEST-Timestamp': Math.floor(new Date().getTime() / 1000)
      }
    : {
        'X-REQUEST-Timestamp': Math.floor(new Date().getTime() / 1000)
      };

  const res: Resp = await httpRequest({
    url,
    sharkParams: {
      app_id: config.appId, // 必填
      app_name: config.appName, // 必填
      activity_id: String(config.actId) // 必填
    },
    useSharkSign: config.enableShark,
    useSharkParam: config.enableShark,
    ...options,
    headers
  });

  const { code, message, data } = res;

  if (code === 0) {
    return data;
  } else {
    const error: any = new Error(
      message || i18n.t('too_many_people_are_currently_active__please_try_later')
    );
    error.code = code;
    return Promise.reject(error);
  }
}
