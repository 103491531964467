import React from 'react';
import './Container.less';
import BaseContainer from '../components/BaseContainer';
import Header from '../components/HeaderForPC';
import Footer from '../components/FooterForPC';
import Section01 from './components/section01';
import Section02 from './components/section02';
import Section03 from './components/section03';
// import SendMail from './components/sendmail';
import useFloatUp from '~/common/hooks/useFloatUp';
import useAreaLimit from '~/common/hooks/useAreaLimit';
import DomainLimit from '~/components/domainLimit';

const floatConfig = {
  list: ['sec1', 'sec2', 'sec3', 'sec4', 'sec5'],
  LogList: [
    { id: 'sec1', name: 'homepage' },
    { id: 'sec2', name: 'news' },
    { id: 'sec3', name: 'overview' },
    { id: 'sec4', name: 'stay_connected' },
    { id: 'sec5', name: 'social_media' }
  ]
};
export default function Container() {
  const { isLimit } = useAreaLimit();

  // 埋点上报
  useFloatUp(floatConfig);

  if (isLimit) {
    return <DomainLimit />;
  }

  return (
    <BaseContainer>
      <Header />
      <Section01 />
      <Section02 />
      {/* <SendMail /> */}
      <Section03 />
      <Footer />
    </BaseContainer>
  );
}
