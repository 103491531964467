import React from 'react';

export default function bootstrap(App: any) {
  const AppWrapper = (props: any) => {
    return <App {...props} />;
  };

  AppWrapper.init = (context) => {
    const { level } = context.request.query;
    if (['0', '1', '2'].includes(level)) {
      // 2 表示服务器渲染，1表示服务端预取数据，0表示客户端渲染
      context.targetRenderLevel = Number(level);
    }
  };

  return AppWrapper;
}
