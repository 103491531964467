/* eslint-disable */
// @ts-nocheck
import { SiteApi } from '@fe/game-sdk';
import { bricksGet } from '../request';
import { localBrowserLanguageDetecter } from '../../utils/credits';

const isSandbox = () => {
  let url = location.origin;
  if (
    url.indexOf('bytedance') > -1 ||
    url.indexOf('sandbox') > -1 ||
    url.indexOf('localhost') > -1
  ) {
    return true;
  }
  return false;
};

export const getSiteInstance = (function () {
  //instance
  let siteApi = null;
  return () => {
    if (!siteApi) {
      siteApi = new SiteApi({
        appId: 262304,
        region: 'va',
        language: 'en-US',
        host: isSandbox() ? null : 'https://www.marvelsnap.com'
      });
    }
    return siteApi;
  };
})();

interface WebsiteConfig {
  website: number;
  channels: Array<Channel>;
}
interface Channel {
  channel: number;
  resource_type: number;
  page_size?: number;
  page_index?: number;
}
const sourceType = {
  ADV: 1,
  NEWS: 2
};
const page_index = 1;
const config: WebsiteConfig = {
  website: 93,
  channels: [
    // {
    //   channel: 1,
    //   resource_type: sourceType.ADV, // 1 广告 2 新闻  3视频
    //   page_size: 9999,
    //   page_index
    // },
    // {
    //   channel: 2,
    //   resource_type: sourceType.ADV, // 1 广告 2 新闻  3视频
    //   page_size: 9999,
    //   page_index
    // },
    // {
    //   channel: 3,
    //   resource_type: sourceType.ADV, // 1 广告 2 新闻  3视频
    //   page_size: 9999,
    //   page_index
    // },
    // {
    //   channel: 4,
    //   resource_type: sourceType.ADV, // 1 广告 2 新闻  3视频
    //   page_size: 9999,
    //   page_index
    // },
    // {
    //   channel: 7,
    //   resource_type: sourceType.ADV, // 1 广告 2 新闻  3视频
    //   page_size: 9999,
    //   page_index
    // },
    // {
    //   channel: 9,
    //   resource_type: sourceType.ADV, // 1 广告 2 新闻  3视频
    //   page_size: 9999,
    //   page_index
    // },
    // {
    //   channel: 10,
    //   resource_type: sourceType.ADV, // 1 广告 2 新闻  3视频
    //   page_size: 9999,
    //   page_index
    // },
    // {
    //   channel: 11,
    //   resource_type: sourceType.NEWS, // 1 广告 2 新闻  3视频
    //   page_size: 9999,
    //   page_index
    // },
    // {
    //   channel: 12,
    //   resource_type: sourceType.NEWS, // 1 广告 2 新闻  3视频
    //   page_size: 9999,
    //   page_index
    // },
    // {
    //   channel: 13,
    //   resource_type: sourceType.NEWS, // 1 广告 2 新闻  3视频
    //   page_size: 9999,
    //   page_index
    // },
    // {
    //   channel: 14,
    //   resource_type: sourceType.NEWS, // 1 广告 2 新闻  3视频
    //   page_size: 9999,
    //   page_index
    // },
    // {
    //   channel: 15,
    //   resource_type: sourceType.NEWS, // 1 广告 2 新闻  3视频
    //   page_size: 9999,
    //   page_index
    // },
    // {
    //   channel: 16,
    //   resource_type: sourceType.NEWS, // 1 广告 2 新闻  3视频
    //   page_size: 9999,
    //   page_index
    // },
    // {
    //   channel: 17,
    //   resource_type: sourceType.NEWS, // 1 广告 2 新闻  3视频
    //   page_size: 9999,
    //   page_index
    // },
    {
      channel: 18,
      resource_type: sourceType.ADV, // 1 广告 2 新闻  3视频
      page_size: 2000,
      page_index
    }
    // {
    //   channel: 19,
    //   resource_type: sourceType.ADV, // 1 广告 2 新闻  3视频
    //   page_size: 9999,
    //   page_index
    // },
    // {
    //   channel: 20,
    //   resource_type: sourceType.ADV, // 1 广告 2 新闻  3视频
    //   page_size: 9999,
    //   page_index
    // },
    // {
    //   channel: 21,
    //   resource_type: sourceType.ADV, // 1 广告 2 新闻  3视频
    //   page_size: 9999,
    //   page_index
    // },
    // {
    //   channel: 22,
    //   resource_type: sourceType.ADV, // 1 广告 2 新闻  3视频
    //   page_size: 9999,
    //   page_index
    // },
    // {
    //   channel: 33,
    //   resource_type: sourceType.NEWS, // 1 广告 2 新闻  3视频

    //   page_size: 9999,
    //   page_index
    // },
    // {
    //   channel: 34,
    //   resource_type: sourceType.NEWS, // 1 广告 2 新闻  3视频
    //   page_size: 9999,
    //   page_index
    // }
  ]
};

export const langMap = {
  en: 'en-US',
  'en-SG': 'en-sea',
  'zh-CN': 'zh-CN',
  'zh-TW': 'zh-TW',
  de: 'de_DE',
  'es-MX': 'es-LA',
  'es-ES': 'es_ES',
  th: 'th-TH',
  vi: 'vi-VN',
  fr: 'fr_FR',
  id: 'id-ID',
  it: 'it-IT',
  ja: 'ja-JP',
  ko: 'ko_KR',
  'pt-BR': 'pt_BR',
  ru: 'ru_RU'
};

// 获取批量资源
export const getBatchData = async () => {
  return getContainData(config);
};
export const getContainData = async (source) => {
  return getSiteInstance().getBatchResource(source);
};

// 获取隐私协议
const privacy: WebsiteConfig = {
  website: 93,
  channels: [
    {
      channel: 5,
      resource_type: sourceType.NEWS, // 1 广告 2 新闻  3视频
      page_size: 9999,
      page_index
    },
    {
      channel: 6,
      resource_type: sourceType.NEWS, // 1 广告 2 新闻  3视频
      page_size: 9999,
      page_index
    }
  ]
};

export const getBatchPrivacyData = async () => {
  return getPrivacyData(privacy);
};
export const getPrivacyData = async (source) => {
  return getSiteInstance().getBatchResource(source);
};
export const getLimitArea = async () => {
  let pre = isSandbox() ? location.protocol + '//act-sandbox.vasnssdk.com' : location.origin;
  const result = await bricksGet(pre + '/external_activity/common/get_ip_info' + '?app_id=262304');

  return result;
};

// 获取媒体数据
export const getMediaList = async () => {
  const res = await getSiteInstance().getPicture({
    page: 3,
    block: 14,
    channel: 18,
    website: 93,
    page_index: 1,
    page_size: 2000
  });
};

// 获取预注册链接
export const getPreBtnInfo: any = async () => {
  try {
    const res = await getSiteInstance().getPicture({
      page: 1,
      block: 1,
      channel: 26,
      website: 93
    });
    // if (res.length > 0) {
    //   let lastTimestep = parseInt(res[0].description);
    //   let url = res[0].url;
    //   let nowUTC8Timestep = Math.round(getUTC8Time() / 1000);
    //   if (nowUTC8Timestep >= lastTimestep) {
    //     return null;
    //   }
    //   let lastDay = Math.ceil((lastTimestep - nowUTC8Timestep) / (24 * 60 * 60));
    //   return {
    //     lastDay: lastDay,
    //     link: url
    //   }
    // }
    if (res.length > 0) {
      let data = res[0];
      let url = data.url;
      if (!url) {
        return null;
      }
      return {
        lastDay: 1,
        link: url
      };
    }
    return null;
  } catch (_) {
    return null;
  }
};

// 获取header动态菜单数据
export const getHeaderLinkConfig: any = async () => {
  try {
    const res = await getSiteInstance().getPicture({
      page: 4,
      block: 22,
      channel: 29,
      website: 93
    });
    if (res.length > 0) {
      let data = res[0];
      let config = JSON.parse(data.description);
      return config;
    }
    return null;
  } catch (_) {
    return null;
  }
};
// 获取header充值中心链接
export const getHeaderTopUpConfig: any = async ({ language }) => {
  let lang = langMap[language];
  if (!lang) {
    language = localBrowserLanguageDetecter();
    lang = langMap[language];
  }
  try {
    const res = await getSiteInstance().getPicture({
      page: 4,
      block: 22,
      channel: 46,
      website: 93,
      language: lang
    });
    if (res.length > 0) {
      let data = res[0]?.url;
      return data;
    }
    return null;
  } catch (_) {
    return null;
  }
};

// 获取ALL
export const getNews = async ({ type, page, size, language }) => {
  const mapArgs = {
    ALL: {
      block: 24,
      channel: 30
    },
    ANNOUNCEMENTS: {
      block: 25,
      channel: 31
    },
    GAME_UPDATES: {
      block: 26,
      channel: 32
    },
    EVENTS: {
      block: 27,
      channel: 33
    },
    MEDIA: {
      block: 28,
      channel: 34
    },
    E_SPORTS: {
      block: 29,
      channel: 35
    }
  };

  let lang = langMap[language];
  if (!lang) {
    language = localBrowserLanguageDetecter();
    lang = langMap[language];
  }
  const res = await getSiteInstance().getNewsWithPage({
    page_index: page,
    page_size: size,
    page: 5,
    website: 93,
    language: lang,
    ...mapArgs[type]
  });
  return res;
};

// 获取新闻详情
export const getNewsDetail = async ({ id, language }) => {
  let lang = langMap[language];
  if (!lang) {
    language = localBrowserLanguageDetecter();
    lang = langMap[language];
  }
  const data = await getSiteInstance().getNewsDetail({
    id: id,
    language: lang
  });
  return data;
};

// 获取置顶新闻
export const getTOPNews = async (language) => {
  let lang = langMap[language];
  if (!lang) {
    language = localBrowserLanguageDetecter();
    lang = langMap[language];
  }
  const res = await getSiteInstance().getNewsWithPage({
    page: 5,
    website: 93,
    block: 30,
    channel: 36,
    language: lang
  });
  return res;
};

// 获取credits页面内容
export const getCredits = async (language) => {
  try {
    const res = await getSiteInstance().getNewsWithPage({
      page: 7,
      website: 93,
      block: 32,
      channel: 39,
      language: language
    });
    let data = res.data;
    let item = data[0];
    const detail = await getSiteInstance().getNewsDetail({ id: item.id, language: language });
    return detail;
  } catch (_) {
    return null;
  }
};

// 获取社区链接
export const getSocialLink = async (language) => {
  try {
    let lang = langMap[language];
    if (!lang) {
      language = localBrowserLanguageDetecter();
      lang = langMap[language];
    }
    const res = await getSiteInstance().getPictureWithPage({
      page: 1,
      website: 93,
      block: 5,
      channel: 9,
      language: lang
    });
    return res.data;
  } catch (_) {
    return [];
  }
};

// 获取PC首页YouTube视频
export const getYoutubeVideo = async (isPc = true, language) => {
  try {
    let lang = langMap[language];
    if (!lang) {
      language = localBrowserLanguageDetecter();
      lang = langMap[language];
    }
    const res = await getSiteInstance().getPictureWithPage({
      page: 1,
      website: 93,
      block: 2,
      channel: isPc ? 2 : 3,
      language: lang
    });
    let data = res.data;
    if (data[0]) {
      return data[0];
    }
    return null;
  } catch (_) {
    return null;
  }
};

// 获取creatorprogram内容
export const getCreatorprogram = async (language) => {
  try {
    let lang = langMap[language];
    if (!lang) {
      language = localBrowserLanguageDetecter();
      lang = langMap[language];
    }
    const res = await getSiteInstance().getNewsWithPage({
      page: 7,
      website: 93,
      block: 45,
      channel: 45,
      language: lang
    });
    let data = res.data;
    let item = data[0];
    const detail = await getSiteInstance().getNewsDetail({ id: item.id, language: lang });
    return detail;
  } catch (_) {
    return null;
  }
};
